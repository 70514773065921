<template>
    <div class="module-knowledge-base" v-if="isPageLoaded">
        <div class="modal-dialog module-set__inner">
            <div class="modal-content">
                <div class="module-set__header">
                    <h3 class="block-title module-set__title">Настройка модуля:</h3>
                    <h3 class="block-title module-set__title">"База знаний"</h3>
                    <button type="button" class="modal__close-btn" @click="$modal.hide('knowledge_base')">x
                    </button>
                </div>

                <div class="modal-staff__tab-head">
                    <div
                        class="modal-staff__tab-name"
                        :class="{'modal-staff__tab-name_active':selectedTab === 1}"
                        @click="selectedTab = 1"
                    >
                        Общие
                    </div>

                    <div
                        class="modal-staff__tab-name"
                        :class="{'modal-staff__tab-name_active':selectedTab === 2}"
                         @click="selectedTab = 2"
                    >
                        Доступ к администрированию
                    </div>

                    <div
                        class="modal-staff__tab-name"
                        :class="{'modal-staff__tab-name_active':selectedTab === 3}"
                        @click="selectedTab = 3"
                    >
                        Доступ к редактированию
                    </div>
                </div>

                <div class="block-content content">
                    <div v-if="selectedTab === 1" class="modal-staff__tab">
                        <div class="module-set__body">
                            <div class="module-set__reduce mt-20">
                                <label for="id_numbering">
                                    <checkbox :value="form.numbering" @input="form.numbering = !form.numbering" id="id_numbering">
                                        Ведение нумерации материалов
                                    </checkbox>
                                </label>
                            </div>
                        </div>
                    </div>

                    <div v-if="selectedTab === 2" class="modal-staff__tab">
                        <div class="module-set__body">
                            <div class="module-set__subtitle">
                                Сотрудники, которые могут администрировать раздел
                                <sup
                                    class="tooltip-mark"
                                    v-tooltip="{
                                        content: 'У пользователя появиться возможность создавать, изменять и удалять материалы в Базе знаний, а так же для каждого материала настраивать доступы отдельным пользователям.'
                                    }"
                                >
                                    ?
                                </sup>
                            </div>

                            <ul class="module-set__list">
                                <li
                                    v-for="(access, index) in form.usersAdmin"
                                    :key="`admin_${access.uuid}`"
                                    class="module-set__item"
                                >
                                    <v-select2
                                        class="v-select2"
                                        label="full_name"
                                        style="width: 100%;"
                                        :reduce="user => user.id"
                                        :options="access.users"
                                        :clearable="false"
                                        :searchable="true"
                                        v-model="access.user_id"
                                    >
                                        <span slot="no-options">Ничего не найдено</span>
                                    </v-select2>

                                    <button
                                        type="button"
                                        class="module-set__del-btn"
                                        @click="removeAdminAccess(index)"
                                    >
                                        <svg width="9" height="9" viewBox="0 0 9 9">
                                            <g>
                                                <g>
                                                    <path fill="#b8b8b8" d="M.694 9.006a.707.707 0 0 1-.503-.21.719.719 0 0 1 0-1.012l3.272-3.29L.19 1.204a.719.719 0 0 1 0-1.013.709.709 0 0 1 1.007 0l3.271 3.29L7.741.191a.709.709 0 0 1 1.007 0 .719.719 0 0 1 0 1.012l-3.272 3.29 3.272 3.291a.719.719 0 0 1 0 1.012.707.707 0 0 1-1.007 0L4.47 5.506l-3.271 3.29a.707.707 0 0 1-.504.21"></path>
                                                </g>
                                            </g>
                                        </svg>
                                    </button>
                                </li>
                            </ul>

                            <button
                                class="module-set__add-btn js-section-add"
                                @click="addAdminUser"
                            >
                                Добавить
                            </button>
                        </div>
                    </div>

                    <div v-if="selectedTab === 3" class="modal-staff__tab">
                        <div class="module-set__body">
                            <div class="module-set__subtitle">
                                Сотрудники, которые могут редактировать раздел
                                <sup
                                    class="tooltip-mark"
                                    v-tooltip="{
                                        content: 'У пользователя появиться возможность создавать и изменять материалы в Базе знаний.'
                                    }"
                                >
                                    ?
                                </sup>
                            </div>

                            <ul class="module-set__list">
                                <li
                                    v-for="(access, index) in form.usersEditor"
                                    :key="`editor_${access.uuid}`"
                                    class="module-set__item"
                                >
                                    <v-select2
                                        class="v-select2"
                                        label="full_name"
                                        style="width: 100%;"
                                        :reduce="user => user.id"
                                        :options="access.users"
                                        :clearable="false"
                                        :searchable="true"
                                        v-model="access.user_id"
                                    >
                                        <span slot="no-options">Ничего не найдено</span>
                                    </v-select2>

                                    <button
                                        type="button"
                                        class="module-set__del-btn"
                                        @click="removeEditorAccess(index)"
                                    >
                                        <svg width="9" height="9" viewBox="0 0 9 9">
                                            <g>
                                                <g>
                                                    <path fill="#b8b8b8" d="M.694 9.006a.707.707 0 0 1-.503-.21.719.719 0 0 1 0-1.012l3.272-3.29L.19 1.204a.719.719 0 0 1 0-1.013.709.709 0 0 1 1.007 0l3.271 3.29L7.741.191a.709.709 0 0 1 1.007 0 .719.719 0 0 1 0 1.012l-3.272 3.29 3.272 3.291a.719.719 0 0 1 0 1.012.707.707 0 0 1-1.007 0L4.47 5.506l-3.271 3.29a.707.707 0 0 1-.504.21"></path>
                                                </g>
                                            </g>
                                        </svg>
                                    </button>
                                </li>
                            </ul>

                            <button
                                class="module-set__add-btn js-section-add"
                                @click="addEditorUser"
                            >
                                Добавить
                            </button>
                        </div>
                    </div>

                    <div class="module-set__footer">
                        <button
                            type="button"
                            class="module-set__cansel-btn v-btn v-btn--transparent"
                            @click="$modal.hide('knowledge_base')"
                        >
                            Отмена
                        </button>

                        <button
                            type="button"
                            class="module-set__save-btn v-btn"
                            @click="onSubmit"
                        >
                            Сохранить
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <li class="module-set__item hidden blank-knowledge-base-js">
            <button class="module-set__del-btn remove-select2-js">
                <svg width="9" height="9" viewBox="0 0 9 9">
                    <g>
                        <g>
                            <path fill="#b8b8b8" d="M.694 9.006a.707.707 0 0 1-.503-.21.719.719 0 0 1 0-1.012l3.272-3.29L.19 1.204a.719.719 0 0 1 0-1.013.709.709 0 0 1 1.007 0l3.271 3.29L7.741.191a.709.709 0 0 1 1.007 0 .719.719 0 0 1 0 1.012l-3.272 3.29 3.272 3.291a.719.719 0 0 1 0 1.012.707.707 0 0 1-1.007 0L4.47 5.506l-3.271 3.29a.707.707 0 0 1-.504.21"/>
                        </g>
                    </g>
                </svg>
            </button>
        </li>
    </div>
</template>

<script>
    import session from '@/api/session'
    import Checkbox from '../../../Checkbox'

    export default {
        name: 'knowledge_base',
        components: {
            Checkbox
        },
        data() {
            return {
                form: {
                    numbering: true,
                    users: []
                },
                selectedTab: 1,
                selectedEditor: null,
                users: [],
                isPageLoaded: false
            }
        },
        methods: {
            addAdminUser() {
                this.form.usersAdmin.push({
                    uuid: this.$uuid.v1(),
                    user_id: null,
                    users: this.users
                })
            },

            addEditorUser() {
                this.form.usersEditor.push({
                    uuid: this.$uuid.v1(),
                    user_id: null,
                    users: this.users
                })
            },

            removeAdminAccess(index) {
                this.form.usersAdmin.splice(index, 1)
            },

            removeEditorAccess(index) {
                this.form.usersEditor.splice(index, 1)
            },

            async onSubmit() {
                try {
                    const data = { ...this.form }

                    data.users = data.users.filter(item => item.user_id).map(item => item.user_id)
                    const request = await session.post('/api/v1/sett_material/save/', data)

                    this.$modal.hide('knowledge_base')
                    this.$swal({
                        title: 'Отлично!',
                        text: 'Данные изменены',
                        icon: 'success',
                        showConfirmButton: false,
                        timer: 1400
                    })
                } catch (error) {
                    console.error(error)
                }
            }
        },

        async created() {
            await session
                .get('/api/v1/users/all_without_admin/')
                .then(response => {
                    const data = response.data
                    this.users = data
                })

            const request = await session.get('/api/v1/sett_material/instance/')
            const { data } = request

            this.form = {
                numbering: data.numbering,
                usersEditor: data.users.map(item => ({
                    user_id: item.id,
                    full_name: item.full_name,
                    users: this.users
                })),
                usersAdmin: data.users.map(item => ({
                    user_id: item.id,
                    full_name: item.full_name,
                    users: this.users
                }))
            }
            this.isPageLoaded = true
        }
    }
</script>

<style lang="scss">
    .module-knowledge-base {
        .vs__selected-options {
            overflow: visible !important;
        }
        .module-set__body {
            padding: 0 10px !important;
        }
    }

    .v--modal-overlay[data-modal="knowledge_base"] {
        .v--modal-box {
            overflow: visible !important;
        }
    }
</style>
